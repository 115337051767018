define("suppliers/components/ace-editor", ["exports", "jquery", "ace/ace"], function (_exports, _jquery, _ace) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var editor = _ace.default.edit(this.get('element'));
      editor.setTheme('ace/theme/monokai');
      editor.getSession().setMode('ace/mode/liquid');
      editor.getSession().setTabSize(2);
      editor.$blockScrolling = Infinity;
      editor.on('change', function () {
        /**
         * update the content property with the lastest changes made in the editor,
         * using the data binding, the content is propagated to the instance of the AdTemplate model
         */
        this.set('content', editor.getSession().getValue());
      }.bind(this));

      // set the initial HTML of an ad template
      editor.getSession().setValue(this.get('content') || '');
      this.set('editor', editor);
      this._setupFlip();
    },
    /**
     * Initializes the flip jQuery plugin which is used for the preview.
     */
    _setupFlip: function _setupFlip() {
      (0, _jquery.default)('#editor-content').flip({
        trigger: 'manual'
      });
    }
  });
  _exports.default = _default;
});