define("suppliers/controllers/ad-template/edit", ["exports", "jquery", "suppliers/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Helper function for actions that insert a tag in the template.
   */
  function tag(content) {
    return function () {
      this.get('editor').insert("{{ ".concat(content, " }}"));
    };
  }
  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    showPreview: false,
    previewUrl: function () {
      return _environment.default.apiURI + '/ad_templates/' + this.get('model.id') + '/preview';
    }.property(),
    showNotification: function showNotification(key, msgType) {
      _jquery.default.notify({
        message: this.get('intl').t('ad-template.edit.notifications.' + key)
      }, {
        type: msgType || key
      });
    },
    saveError: function saveError() {
      this.showNotification('error', 'danger');
    },
    saveSuccess: function saveSuccess() {
      this.showNotification('success');
    },
    finishSuccess: function finishSuccess() {
      this.transitionToRoute('adTemplates.finished');
    },
    flipPreviewPane: function flipPreviewPane() {
      (0, _jquery.default)('#editor-content').flip(true);
      this.showNotification('success');
      this.set('showPreview', true);
    },
    switchToPreview: function switchToPreview() {
      this.flipPreviewPane();
    },
    actions: {
      preview: function preview() {
        this.get('model').save().then(this.switchToPreview.bind(this)).catch(this.saveError.bind(this));
      },
      save: function save() {
        this.get('model').save().then(this.saveSuccess.bind(this)).catch(this.saveError.bind(this));
      },
      saveAndFinish: function saveAndFinish() {
        var template = this.get('model');
        template.set('state', 'published');
        template.save().then(this.finishSuccess.bind(this));
      },
      backToEditor: function backToEditor() {
        (0, _jquery.default)('#editor-content').flip(false, function () {
          this.set('showPreview', false);
        }.bind(this));
      },
      insertPosition: tag('position'),
      insertCompanyLogo: tag('company_logo'),
      insertJobId: tag('id'),
      insertCompanyDescription: tag('descriptions | find_description: "company_description"'),
      insertJobDescription: tag('descriptions | find_description: "job_description"'),
      insertQualifications: tag('descriptions | find_description: "qualifications"'),
      insertFurtherInformations: tag('descriptions | find_description: "further_information"'),
      insertContactInformation: tag('descriptions | find_description: "contact_information"'),
      insertLocation: tag('location'),
      insertLocationName: tag('location_name'),
      insertLocationStreet: tag('location_street'),
      insertLocationCity: tag('location_city'),
      insertLocationRegion: tag('location_region'),
      insertLocationPostalCode: tag('location_postal_code'),
      insertLocationCountry: tag('location_country'),
      insertIndustry: tag('industry'),
      insertClassification: tag('classification'),
      insertSeniority: tag('seniority'),
      insertSchedule: tag('schedule'),
      insertCategory: tag('category'),
      insertYearsOfExperience: tag('years_of_experience'),
      insertOccupations: tag('occupations'),
      insertKeywords: tag('keywords'),
      insertDepartment: tag('department'),
      insertUrl: tag('url'),
      insertCareerSiteUrl: tag('career_site_url'),
      insertApplyUrl: tag('apply_url'),
      insertApplyEmail: tag('apply_email'),
      insertHeaderImage: tag('header_image'),
      insertSecondHeaderImage: tag('second_header_image'),
      insertThirdHeaderImage: tag('third_header_image'),
      insertVideoUrl: tag('video_url'),
      insertAdditionalCities: tag('additional_cities')
    }
  });
  _exports.default = _default;
});