define("suppliers/routes/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    beforeModel: function beforeModel() {
      return this.get('intl').setLocale('de-de');
    }
  });
  _exports.default = _default;
});