define("suppliers/router", ["exports", "suppliers/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType
  });
  Router.map(function () {
    this.route('adTemplates', {
      resetNamespace: true
    }, function () {
      this.route('finished');
      this.route('adTemplate', {
        resetNamespace: true,
        path: '/:guest_token'
      }, function () {
        this.route('edit');
      });
    });
  });
  var _default = Router;
  _exports.default = _default;
});