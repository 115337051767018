define("suppliers/routes/ad-template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.store.find('adTemplate', params.guest_token);
    }
  });
  _exports.default = _default;
});